import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import { Button } from '../components/elements'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../components/seo"

const Orchard = () => {
  const data = useStaticQuery(graphql`
    query {
      orchard: file(relativePath: { regex: "/orchard-screenshot/" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      orchardMobile: file(relativePath: { regex: "/orchard-mobile/" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Example 1, Pub" />
      <InfoPagesWrapper>
        <h1>The Orchard House, Kilkenny</h1>

        <p>
          The Orchard House in Kilkenny is a relaxing and traditional pub
          located just outside Kilkenny City. The concept was a clean design
          that showcases the pub, but also an online ordering system for
          customers to order take away.
        </p>
        <a href="https://theorchardhouse.ie/" target="_blank" rel="noreferrer">
          <Button>Take me there!</Button>
        </a>
      </InfoPagesWrapper>
      <div
        css={`
          width: 80%;
          margin: 2rem auto;
          img {
            z-index: -1 !important; // AHHHHH no idea why but this fixed the problem w/ it covering the header
            overflow: hidden !important;
          }
        `}
      >
        <div
          css={`
            width: 40%;
            margin: 2rem auto;
          `}
        >
          <Img fluid={data.orchardMobile.childImageSharp.fluid} />
        </div>
        <Img fluid={data.orchard.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export default Orchard
